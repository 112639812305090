<template>
  <div>
    <el-tabs v-model="activeTab" @tab-click="tabClick">
      <el-tab-pane :label="$t('dialogBatchCommand.sendCommand')" name="first">
        <div class="tab-wrapper tab-wrapper--flex">
          <div class="tab-nav tab-nav--is-tree" v-show="currentCommandSingle">
            <tree-user
              v-if="treeVisible && loginInfo.allFuns.includes(20121)"
              :title="$t('dialogBatchCommand.selectVehicle')"
              show-icon
              show-vehicle
              :show-object-count.sync="showObjectCount"
              :use-local-setting="false"
              :mdtTypeID="currentCommand"
              @check="treeCheck"
            ></tree-user>
          </div>
          <div class="tab-body" :style="{marginLeft:(currentCommandSingle?'50px':'260px')}">
            <div class="tab-form imx-1 imb-0" style="height:36px;overflow-y:hidden;">
              <el-button @click="openDialog" class="tab-button" :class="$i18n.locale" :loading="btnLoading" type="text">{{$t('dialogBatchCommand.selectDevices', {count: checkedObjectKeys.length})}}</el-button>
            </div>
            <div class="tab-form imx-1 imb-3" v-show="currentCommandSingle">
              <span class="label sub-title" :class="$i18n.locale">{{$t('dialogBatchCommand.selectType')}}：</span>
              <el-select v-model="currentCommand">
                <el-option
                  v-for="item in config.MDTTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="tab-form imx-1 imb-0">
              <span class="label sub-title" :class="$i18n.locale">{{$t('dialogBatchCommand.selectCommand')}}：</span>
              <el-popover
                v-model="popoverCommand"
                transition="el-zoom-in-top"
                placement="bottom"
                width="600"
                trigger="click"
                popper-class="v-select-box--options">
                <select-box class="v-slect-box--command" slot="reference" active>
                  {{$t('commandList')[form.currentField.name] || form.currentField.name}}
                </select-box>
                <div v-if="category" class="category">
                  <span v-for="(cate, index) in category" :key="index" :class="{active:index==activeCategoryIndex}" @click="activeCategoryIndex=index">{{$t('dialogBatchCommand')[cate.name]||cate.name}}</span>
                </div>
                <div class="v-select-box--options__block" v-if="false">
                  <p class="block__title">{{$t('dialogBatchCommand.recentUse')}}</p>
                  <div class="block__content"></div>
                </div>
                <div class="v-select-box--options__block">
                  <p class="block__title">{{$t('dialogBatchCommand.usuallyCommand')}}</p>
                  <div class="block__content">
                    <div class="content-item" :class="{active:item.title===form.currentField.name}" v-for="(item, index) in currentHotCommand" :key="index" @click="() => selectCommand(item)">
                      {{index+1}}.{{$t('commandList')[item.title] || item.title}}
                      <div class="icon" @click.stop="toggleLike(item)">
                        <!-- <i class="el-icon-star-on" style="color: #00ACC1;" v-if="isLike(item)"></i>
                        <i class="el-icon-star-off" style="color: #00ACC1;" v-else></i> -->
                        <i-icon name="iconxinxiguanli_yiguanzhu" v-if="isLike(item)" ></i-icon>
                         <i-icon name="iconxinxiguanli_weiguanzhu" v-else></i-icon>
                      </div>
                    </div>
                  </div>
                  <p class="block__title" v-if="isShowMore">{{$t('dialogBatchCommand.moreCommand')}}</p>
                  <div class="block__content" v-if="isShowMore">
                    <div class="content-item" :class="{active:item.title===form.currentField.name}" v-for="(item, index) in currentNotHotCommand" :key="index" @click="() => selectCommand(item)">
                      {{index+1}}.{{$t('commandList')[item.title] || item.title}}
                      <div class="icon" @click.stop="toggleLike(item)">
                        <!-- <i class="el-icon-star-on" style="color: #00ACC1;" v-if="isLike(item)"></i>
                        <i class="el-icon-star-off" style="color: #00ACC1;" v-else></i> -->
                        <i-icon name="iconxinxiguanli_yiguanzhu" v-if="isLike(item)" ></i-icon>
                        <i-icon name="iconxinxiguanli_weiguanzhu" v-else></i-icon>
                      </div>
                    </div>
                  </div>
                  <el-button type="text" @click="isShowMore=!isShowMore" class="iml-0" v-if="currentNotHotCommand.length>0">
                    <i class="el-icon-caret-top" v-if="isShowMore"></i>
                    <i class="el-icon-caret-bottom" v-else></i>
                    {{isShowMore?$t('dialogBatchCommand.collapse'):$t('dialogBatchCommand.expand')}}
                  </el-button>
                </div>
              </el-popover>
            </div>
            <template v-if="(currentCommand===20||currentCommand===19) && form.currentField.name==='高级指令'">
              <div class="tab-form imx-1" v-for="field in form.currentField.fields" :key="field.name">
                <span class="label sub-title" style="text-align:right;"  :class="$i18n.locale" v-if="field.label !=='该指令无需参数' && field.fieldType !=='checkbox'">{{$t('commandParams')[field.label] || field.label}}：</span>
                <div class="content">
                    <div class="imb-1">
                        <div class="area" v-if="field.fieldType!=='tip'" style="width: 217px;">
                          <i-field v-model="form.params[field.name]" v-bind="field"></i-field>
                        </div>
                    </div>
                </div>
              </div>
             </template>
             <template v-else>
              <div class="tab-form imx-1 tab-form--setting" style="margin-bottom:3px">
                <span class="label sub-title" :class="$i18n.locale" style="margin-top:17px;">{{$t('dialogBatchCommand.setOptions')}}：</span>
                <div class="content">
                  <div class="imb-1" v-for="field in form.currentField.fields" :key="field.name">
                    <div class="area" v-if="field.fieldType!=='tip'">
                      <span class="area-label" v-if="field.label !=='该指令无需参数' && field.fieldType !=='checkbox'">{{$t('commandParams')[field.label] || field.label}}</span>
                      <div class="area-item">
                        <i-field v-model="form.params[field.name]" v-bind="field"></i-field>
                      </div>
                    </div>
                    <i-field v-else v-model="form.params[field.name]" v-bind="field"></i-field>
                  </div>
                </div>
              </div>
             </template>
            
            <div class="tab-form imx-1">
              <div class="tab-button" :class="$i18n.locale">
                <el-button type="primary" class="border" plain @click.stop="send" :loading="sendLoading" style="width: 217px;" v-ripple>{{$t('dialogBatchCommand.sendButtonText')}}</el-button>
              </div>
            </div>
          </div>
        </div>
        <el-dialog :visible.sync="dialogObjectList" :title="$t('dialogBatchCommand.selectedList')" custom-class="v-dialog v-dialog--sm v-dialog--mdpadding v-dialog--footer" class="v-dialog-commandlist v-dialog--center" append-to-body>
          <el-table :data="checkedObjectList" class="v-table__customStyle" row-key="ObjectID" style="width:100%;" max-height="348" ref="multiTable">
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column :label="$t('dialogBatchCommand.vehicleName')" prop="VehicleName" width="150"></el-table-column>
            <el-table-column :label="$t('dialogBatchCommand.deviceID')" prop="SIM2"></el-table-column>
            <el-table-column :label="$t('dialogBatchCommand.wireType')" width="120">
              <template slot-scope="{row}">
                <p style="color:#3F51B5;">{{$t('dict')[row.TrackerTypeName]}}</p>
              </template>
            </el-table-column>
            <el-table-column :label="$t('dialogBatchCommand.isOnline')" width="120">
              <template slot-scope="{row}">
                <span class="square online" v-if="row.isOnline"></span>
                <span class="square offline" v-else></span>
              </template>
            </el-table-column>
          </el-table>
          <div slot="footer">
            <el-button class="border" plain type="primary" @click="removeSelection">{{$t('common.removeChecked')}}</el-button>
            <el-button class="border" plain type="primary" @click="removeAll">{{$t('common.removeAll')}}</el-button>
            <el-button class="border" plain type="primary" @click="dialogObjectList=false">{{$t('common.close')}}</el-button>
          </div>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane :label="$t('dialogBatchCommand.commandHistory')" name="second">
        <div class="tab-wrapper tab-wrapper--flex">
          <div class="tab-body">
            <el-row class="imb-1 gs-row" type="flex" justify="start" align="middle">
              <p>{{$t('dialogBatchCommand.queryTime')}}：</p>
              <el-date-picker class="imr-1" type="datetimerange" v-model="historyRange" start-placeholde="" end-placeholde="" size="small"></el-date-picker>
              <el-checkbox v-model="hasChild" class="imr-1">{{$t('commonTreeUser.includeChild')}}</el-checkbox>
              <el-button icon="el-icon-search" class="border" plain type="primary" @click="fetchHistory" size="small">{{$t('dialogBatchCommand.queryButton')}}</el-button>
            </el-row>

            <el-table class="v-table__customStyle" :data="history.data" style="width:100%;" height="420px">
              <el-table-column :label="$t('dialogBatchCommand.commandName')" prop="CommandName">
                <span slot-scope="{row}">{{$t('commandList')[row.CommandName] || row.CommandName}}</span>
              </el-table-column>
              <el-table-column :label="$t('dialogBatchCommand.commandContent')" prop="CommandBody" width="180" show-overflow-tooltip></el-table-column>
              <el-table-column :label="$t('dialogBatchCommand.commandDeviceCount')" prop="TotalCount" width="110"></el-table-column>
              <el-table-column :label="$t('dialogBatchCommand.Resend')" prop="ReSendCount" width="90"></el-table-column>
              <el-table-column :label="$t('dialogBatchCommand.successRate')">
                <template slot-scope="{row}">
                  <p v-if="row.TotalCount===row.ReSendCount" style="color:#BDBDBD;">{{$t('dialogBatchCommand.allResend')}}</p>
                  <p v-else>{{(100 * row.SuccessCount/(row.TotalCount-row.ReSendCount)).toFixed(0)}}%</p>
                </template>
              </el-table-column>
              <el-table-column :label="$t('dialogBatchCommand.time')" width="160">
                <div slot-scope="{row}" :title="row.CreateTime">
                  <span v-if="$i18n.locale==='zh'">{{row.TimeDesc}}</span>
                  <span v-else>{{miment(row.CreateTime).format('YYYY/MM/DD hh:mm')}}</span>
                  <p v-if="hasChild">{{row.HoldName}}</p>
                </div>
              </el-table-column>
              <el-table-column :label="$t('dialogBatchCommand.options')">
                <p slot-scope="{row}">
                  <span class="i-link" @click="() => openHistoryDetailDialog(row)">{{$t('dialogBatchCommand.view')}}</span>
                  <span class="i-link iml-1" @click="reSendAll(row)">{{$t('dialogBatchCommand.reSend')}}</span>
                </p>
              </el-table-column>
            </el-table>
            <el-pagination
              background
              class="v-pager imx-2 imt-1 imb-3"
              :page-size="history.pageSize"
              :current-page.sync="history.pageIndex"
              layout="total, sizes, prev, pager, next, jumper"
              :total="history.totalCount"
              popper-class="custom"
              @current-change="fetchHistory">
            </el-pagination>

            <el-dialog :title="$t('dialogBatchCommand.sendDetail')" append-to-body lock-scroll :visible.sync="historyDetailDialog" custom-class="v-dialog v-dialog--md v-dialog--mdpadding v-dialog--footer">
              <el-table :data="historyDetail" class="v-table__customStyle" style="width:100%" max-height="528" ref="historyDetailTable">
                <el-table-column type="selection"></el-table-column>
                <el-table-column :label="$t('dialogBatchCommand.vehicleName')" prop="VehicleName"></el-table-column>
                <el-table-column :label="$t('dialogBatchCommand.deviceID')" prop="SIM2"></el-table-column>
                <el-table-column :label="$t('dialogBatchCommand.commandName')" prop="CmdDesc">
                  <span slot-scope="{row}">{{$t('commandList')[row.CmdDesc] || row.CmdDesc}}</span>
                </el-table-column>
                <el-table-column :label="$t('dialogBatchCommand.commandContent')" prop="CmdContent"></el-table-column>
                <el-table-column :label="$t('dialogBatchCommand.sendStatus')">
                  <template slot-scope="{row}">
                    <p v-if="!row.CmdDesc" style="color:#f44336;">{{$t('dialogBatchCommand.allResend')}}</p>
                    <p v-else>{{!row.SendFlag&&!row.SendResult? $t('dialogBatchCommand.sending') : (row.FailReason === '指令下发成功' ? $t('dialogBatchCommand.sendSuccessed') : row.FailReason)}}</p>
                  </template>
                </el-table-column>
              </el-table>
              <div slot="footer">
                <el-button class="border" plain type="primary" @click="reSendRows($refs.historyDetailTable.selection)">{{$t('dialogBatchCommand.reSendChecked')}}</el-button>
                <el-button class="border" plain type="primary" @click="reSendAll()">{{$t('dialogBatchCommand.reSendAll')}}</el-button>
                <el-button class="border" plain type="primary" @click="fetchHistoryDetail()">{{$t('dialogBatchCommand.refresh')}}</el-button>
                <el-button class="border" plain type="primary" @click="historyDetailDialog=false">{{$t('common.close')}}</el-button>
              </div>
            </el-dialog>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import SelectBox from '@/components/select-box'
import TreeUser from '@/views/common/tree-user'
import * as config from './config/batch-command-config'
import { findFromArray,deleteFromArray,utcTime } from '@/common/utils'
import IField from '@/components/i-field.i18n'
import { GetObjectIdByMixID } from '@/api/common'
import { GetObjectListByObjectIDs, SendCommand,BatchCommandHistory,BatchCommandDetail,ReSendCommandList } from '@/api/command'
import { setLikeCommandList, getLikeCommandList } from '@/common/cache'
import miment from 'miment'
import { mapGetters } from 'vuex'

export default {
  props: {
    currentCommandDefault: {
      type: Number,
      default: 10
    },
    checkedObjectKeysDefault: {
      type: Array,
      default: () => []
    },
    currentType: {
      type: Number,
      default: 0
    },
    currentCommandSingle:{
      type: Boolean,
      default: false
    }
  },
  components: {
    SelectBox,
    TreeUser,
    IField,
  },
  data() {
    return {
      miment,
      hasChild: false,
      activeTab: 'first',
      treeVisible: true,
      checkedKeys: [],
      btnLoading: false,
      checkedObjectKeys: this.checkedObjectKeysDefault.slice(),
      dialogObjectList: false,
      checkedObjectList: [],
      likeCommandList: [], // 格式：[指令类型]_[指令名称]  例：vdf_回传间隔
      config: Object.assign({}, config),
      popoverCommand: false,
      currentCommand: this.currentCommandDefault, // vdf
      form: {
        currentField: {},
        params: {}
      },
      historyRange: [miment().add(-7, 'DD'), miment().add(1, 'DD')],
      history: {
        data: [],
        totalCount: 0,
        pageIndex: 1,
        pageSize: 10
      },
      historyDetail: [],
      historyDetailDialog: false,
      hotCommandList: [],
      showObjectCount: false,
      runWave: false,
      sendLoading: false,
      isShowMore: false,
      category: null,
      activeCategoryIndex: this.currentType,
      notAllow: false
    }
  },
  computed: {
    selectedMdtTypeItem() {
      // 选中的指令格式类型
      let item = findFromArray(this.config.MDTTypeList, k => k.value === this.currentCommand)
      // eslint-disable-next-line
      if (item && item.category) {
        // eslint-disable-next-line
        this.category = item.category
      } else {
        // eslint-disable-next-line
        this.category = null
      }

      return item
    },
    currentCommandList() {
      // 当前指令列表
      let list = this.config[`commandList_${this.selectedMdtTypeItem.label.toLowerCase()}`]
      if (this.category && list) {
        let currentCategory = this.category[this.activeCategoryIndex]
        list = list.filter(k => currentCategory.list.includes(k.title))
      }
      return list
    },
    currentFieldList() {
      // 指令参数列表
      // return this.config[`command_${this.selectedMdtTypeItem.label.toLowerCase()}`]
      let list = this.config[`command_${this.selectedMdtTypeItem.label.toLowerCase()}`]
      if (this.category && list) {
        let currentCategory = this.category[this.activeCategoryIndex]
        list = list.filter(k => currentCategory.list.includes(k.name))
      }
      return list
    },
    currentHotCommand() {
      let hotList = this.hotCommandList[this.selectedMdtTypeItem.label.toLowerCase()]
      return this.currentCommandList.filter(k => !hotList || hotList.includes(k.title))
    },
    currentNotHotCommand() {
      let hotList = this.hotCommandList[this.selectedMdtTypeItem.label.toLowerCase()]
      if (!hotList) {
        return []
      }
      return this.currentCommandList.filter(k => !hotList.includes(k.title))
    },
    ...mapGetters([
      'loginInfo'
    ])
  },
  watch: {
    activeTab(val) {
      if (val === 'second' && this.history.data.length === 0) {
        this.fetchHistory()
      }
    },
    checkedObjectKeysDefault(val) {
      this.checkedObjectKeys = val.slice()
    },
    currentCommandDefault(val) {
      this.currentCommand = val
    },
    currentType(val) {
      this.activeCategoryIndex = val
    },
    currentCommand() {
      this.form.currentField = this.currentFieldList[0]
      this.checkedObjectKeys.splice(0)
      this.checkedObjectList.splice(0)
      this.treeVisible = false
      setTimeout(() => {
        this.treeVisible = true
      })
    },
    'form.currentField': {
      deep: true,
      handler(val) {
        for (let key in this.form.params) {
          this.$delete(this.form.params, key)
        }
        val.fields.forEach(item => {
          this.$set(this.form.params, item.name, item.value )
        })
      }
    },
    likeCommandList(val) {
      setLikeCommandList(val)
    }
  },
  created() {
    if (!config.MDTTypeList.map(k => k.value).includes(this.currentCommandDefault)) {
      this.$alert(this.$t('dialogBatchCommand.unsupported'))
    }

    // this.form.currentField = this.config.command_vdf[0]
    this.form.currentField = this.currentFieldList[0]
    this.likeCommandList = getLikeCommandList()
    this.hotCommandList = this.config['command_hot_list']

    if (this.loginInfo.HoldID != 1) {
      var notAllow = ['设置接入参数']
      this.config.commandList_808 = this.config.commandList_808.filter(k => !notAllow.includes(k.title))
    }
  },
  methods: {
    fetchMixIds() {
      this.btnLoading = true
      GetObjectIdByMixID(this.checkedKeys, this.currentCommand).then(ret => {
        this.btnLoading = false
        this.checkedObjectKeys = ret.data
      })
    },
    fetchObjectList() {
      this.btnLoading = true
      const promise = new Promise((resolve) => {
        GetObjectListByObjectIDs(this.checkedObjectKeys).then(ret => {
          this.btnLoading = false
          this.checkedObjectList = ret.data.list
          resolve()
        })
      })
      return promise
    },
    fetchHistory() {
      const promise = new Promise((resolve) => {
        BatchCommandHistory(this.history.pageIndex, this.history.pageSize, '', '', this.historyRange[0], this.historyRange[1], this.hasChild).then(ret => {
          this.history.totalCount = ret.data.total
          this.history.data = ret.data.list
          if (this.history.data.length === 0) {
            this.$message(this.$t('common.noResults'))
          }
          resolve()
        })
      })
      return promise
    },
    fetchHistoryDetail(bid) {
      if (bid) {
        this.fetchHistoryDetail_back = bid
      } else {
        bid = this.fetchHistoryDetail_back
      }

      const promise = new Promise((resolve) => {
        BatchCommandDetail(bid).then(ret => {
          this.historyDetail = ret.data.list
          resolve(ret)
        })
      })
      return promise
    },
    async openHistoryDetailDialog(row) {
      await this.fetchHistoryDetail(row.BID)
      this.historyDetailDialog = true
      setTimeout(() => {
        this.runWave = true
      }, 500)
    },
    async openDialog() {
      if (this.checkedObjectKeys.length >= 1000) {
        this.$alert(this.$t('dialogBatchCommand.unableMsg'))
        return
      }

      await this.fetchObjectList()
      this.dialogObjectList = true
    },
    tabClick() {

    },
    treeCheck(keys) {
      this.checkedKeys = keys.slice()
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.fetchMixIds()
        this.timer = null
      }, 50)
    },
    selectCommand(item) {
      this.form.currentField = this.getFieldByTitle(item.title)
      this.popoverCommand = false
    },
    getFieldByTitle(title) {
      return findFromArray(this.currentFieldList, k => k.name === title)
    },
    toggleLike(item) {
      if (this.isLike(item)) {
        this.disLike(item)
      } else {
        this.like(item)
      }
    },
    isLike(item) {
      return this.likeCommandList.indexOf(`${this.selectedMdtTypeItem.label.toLowerCase()}_${item.title}`) != -1
    },
    like(item) {
      this.likeCommandList.push(`${this.selectedMdtTypeItem.label.toLowerCase()}_${item.title}`)
    },
    disLike(item) {
      deleteFromArray(this.likeCommandList, k => k === `${this.selectedMdtTypeItem.label.toLowerCase()}_${item.title}`)
    },
    removeSelection() {
      const list = this.$refs.multiTable.selection
      list.forEach(k => {
        deleteFromArray(this.checkedObjectList, j => j.ObjectID === k.ObjectID)
        deleteFromArray(this.checkedObjectKeys, j => j === k.ObjectID)
      })
    },
    removeAll() {
      this.checkedObjectList.splice(0)
      this.checkedObjectKeys.splice(0)
    },
    reSendAll(item) {
      if (item) {
        this.fetchHistoryDetail(item.BID).then(ret => {
          this.reSendRows(ret.data.list)
        })
      } else {
        this.reSendRows(this.historyDetail)
      }
    },
    reSendRows(rows) {
      if (rows.length === 0) {
        this.$message(this.$t('common.emptySelectedTip'))
        return
      }
      const newRows = rows.filter(k => !!k.CmdDesc)
      if (newRows.length === 0) {
        this.$message({message:this.$t('dialogBatchCommand.allResend'), type: 'warning'})
        return
      }
      this.reSend(newRows.map(k => k.ObjectID), newRows.map(k => k.CmdDesc), newRows.map(k => k.CmdContent))
      this.historyDetailDialog = false
    },
    reSend(objectIdList, commandNameList, commandTextList) {

      ReSendCommandList(objectIdList, commandNameList, commandTextList).then(ret => {
        if (ret.data.errCode) {
          this.$alert(ret.data.errMsg)
        } else {
          this.$message({
            message: this.$t('dialogBatchCommand.sendSuccessed'),
            type: 'success'
          })
          this.fetchHistory()
        }
      })
    },
    send() {
      // this.$alert(`<p>${this.$t('dialogBatchCommand.commandName')}：${this.form.currentField.name}</p><p>${this.$t('dialogBatchCommand.commandContent')}：${JSON.stringify(this.form.params)}</p>`, '', {
      //   dangerouslyUseHTMLString: true
      // })
      if (this.checkedObjectKeys.length === 0) {
        this.$alert(this.$t('common.emptySelectedTip'))
        return
      }
      let datas = this.form.params
      let params = ''
      if(this.form.currentField.name=='无线闹钟'){
        let item = {
          huanxing_shijian1: datas.huanxing_shijian1?utcTime(datas.huanxing_shijian1):null,
          huanxing_shijian2: datas.huanxing_shijian2?utcTime(datas.huanxing_shijian2):null,
          huanxing_shijian3: datas.huanxing_shijian3?utcTime(datas.huanxing_shijian3):null,
          huanxing_shijian4: datas.huanxing_shijian4?utcTime(datas.huanxing_shijian4):null,
          tuichu_zhuizong: datas.tuichu_zhuizong,
          xiumian_shichang: datas.xiumian_shichang
        }
        params = item
        // datas = Object.assign(datas,item)
      }else{
        params = datas
      }
      const dataSleep = this.form.params.xiumian_shichang //5-43200
      const dataInterval = this.form.params.huichuan_jiange //30-300
      if(dataSleep){
        if(dataSleep<5){
          this.$message({
              message: this.$t('dialogBatchCommand.sleepErroMsg'),
              type: 'error'
          })
          return
        }
        if(dataSleep>43200){
          this.$message({
              message: this.$t('dialogBatchCommand.sleepErroMsg2'),
              type: 'error'
          })
          return
        }
      }
      if(dataInterval){
        if(dataInterval<30){
          this.$message({
              message: this.$t('dialogBatchCommand.intervalErroMsg'),
              type: 'error'
          })
        }
        if(dataInterval<5){
          this.$message({
              message: this.$t('dialogBatchCommand.intervalErroMsg'),
              type: 'error'
          })
          return
        }
        if(dataInterval>300){
          this.$message({
              message: this.$t('dialogBatchCommand.intervalErroMsg2'),
              type: 'error'
          })
          return
        }
      }
      this.sendLoading = true
      SendCommand(this.checkedObjectKeys, this.selectedMdtTypeItem.label, this.form.currentField.name, JSON.stringify(params)).then(ret => {
        if (ret.data.errCode) {
          this.$alert(ret.data.errMsg)
        } else {
          this.$alert(this.$t('dialogBatchCommand.sendSuccessed'))
        }
      }).catch(err => {
        this.$message({
          message: this.$t('dialogBatchCommand.sendError'),
          type: 'error'
        })
        console.error(err)
      }).finally(() => {
        this.sendLoading = false
      })
      
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/mixin.scss';
.tab-wrapper {
  height: 535px;
  &.tab-wrapper--flex {
    display: flex;
    .tab-nav {
      width: 260px;
      flex-shrink: 0;
      color: #fff;
      &.tab-nav--is-tree {
        position: relative;
        height: 100%;
      }
    }
    .tab-body {
      flex-grow: 1;
    }
  }
}
$zhWidth: 5em;
$enWidth: 10em;
.tab-body {
  color: #fff;
  .tab-form {
    display: flex;
    align-items: flex-start;
    color: #fff;
    .label {
      color: #fff;
      width: $zhWidth;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      height: 32px;
      line-height: 32px;
      &.en {
        width: $enWidth;
      }
    }
    .v-slect-box--command {
      border-radius: 2px;
      display: inline-block;
      height: 32px;
      line-height: 30px;
      width: 217px;
      padding: 0 15px;
      color: #606266;
      cursor: pointer;
      user-select: none;
      white-space: nowrap;
      text-overflow: ellipsis;
      // overflow: hidden;
    }
    & ::v-deep .el-input__inner {
      font-size: 14px;
    }
    .tab-button {
      font-size: 16px;
      margin-left: $zhWidth;
      
       & ::v-deep .el-button{
          color: #000;
          border:none;
          background: url(../../assets/images/form-btn1.png) no-repeat center;
          background-size: 60% 100%;
       }
      &.en {
        margin-left: $enWidth;
      }
    }
  }
  .gs-row{
    & ::v-deep .el-input__inner{
      border-color: #27446b;
      color: #fff;
      background-color: #001025;
      .el-range-input{
        color: #fff;
        background-color: #001025;
      }
    }
    & ::v-deep .el-checkbox__label{
      color: #fff;
    }
    & ::v-deep .el-button{
      background-color: #000416;
      border-color: #27446b;
      color: #fff;
    }
  }
  .tab-form--setting {
    display: flex;
    .sub-title {
      flex-shrink: 0;
    }
    .content {
      flex-grow: 1;
      max-height: 345px;
      overflow-y: auto;
      @include scroll-bar-grey;
      .area {
        .area-label {
          margin: 2px 0;
          font-size: 12px;
          color: #fff;
          display: block;
        }
        .area-item {
          max-width: 217px;
          color: #fff;
          & ::v-deep .el-input__inner {
            color:#fff;
            background-color: #001025;
            border-color: #001025;
            border-bottom-color: #617895;
            text-align: left;
          }
        }
      }
    }
  }
}
.v-pager{
  & ::v-deep .el-input__inner{
    color: #0095FE;
    border: 0;
    border-radius: 2px!important;
    background-color: #16263F;
  }
  & ::v-deep  li{
    color: #0095FE !important;
    background-color: #001025 !important;
  }
  & ::v-deep .btn-prev{
    color: #0095FE;
    background-color: #001025;
  }
  & ::v-deep .btn-next{
    color: #0095FE;
    background-color: #001025;
  }
  & ::v-deep .el-pagination__total{
    color: #0095FE;
  }
}
.v-dialog-commandlist {
  // & ::v-deep th {
  //   background-color: #CFD8DC !important;
  //   color: #455A64 !important;
  // }
  // & ::v-deep .el-table--mini th, .el-table--mini td {
  //   padding: 3px 0;
  // }
  .square {
    height: 12px;
    width: 12px;
    display: inline-block;
    border-radius: 2px;
    &.online {
      background-color: #4CAF50;
    }
    &.offline {
      background-color: #BDBDBD;
    }
  }
}
.category {
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
  border-bottom: 1px solid #EEE;
  margin-bottom: 8px;
  span {
    height: 32px;
    line-height: 32px;
    padding: 0 12px;
    display: inline-block;
    cursor: pointer;
    &.active {
      color: #3a78c5;
      border-bottom: 1px solid var(--color-p);
    }
  }
}
  .el-tabs{
     & ::v-deep {
        .el-tabs__item {
          color: #dff1fd;
        }
     }
  }
 .el-table {
        border: 1px solid #427291;
        border-radius: 10px;
        & ::v-deep .el-table__header{
          th{
            padding: 8px 0;
            background: #001026;
            color: #a1a1a1;
            font-weight: 600;
            border-bottom: none;
            border-right: none;
          }
        }
         & ::v-deep .el-table__body-wrapper{
           background-color: #001026;
        }
         & ::v-deep .el-table__body{
           td{
            background-color: #001026 !important;
            color:#fff;
            border: none !important;
           }
           td:hover{
            background-color: #000 !important;
           }
        }
        &::before {
          background-color: #126585;
        }
        &::after {
          background-color: #126585;
        }

      }
</style>
