<template>
  <!--普通文本框-->
  <el-input
    v-if="fieldType === 'textfield'"
    :placeholder="$t('commandParams')[label] || label"
    v-model="innerValue"
    v-bind="others"
    clearable
  >
    <span
      slot="suffix"
      v-if="others && others.suffix"
      style="line-height: 32px"
      >{{ $t("commandParams")[others.suffix] || others.suffix }}</span
    >
  </el-input>
  <!--数字文本框-->
  <el-input-number
    v-else-if="fieldType === 'numberfield'"
    class="number"
    :controls="false"
    :min="min"
    :max="max"
    :step="step"
    :placeholder="placeholder || $t('commandParams')[label] || label"
    v-model="innerValue"
    v-bind="others"
  >
    <span
      slot="suffix"
      v-if="others && others.suffix"
      style="line-height: 32px"
      >{{ $t("commandParams")[others.suffix] || others.suffix }}</span
    >
  </el-input-number>
  <!--Radio-->
  <div v-else-if="fieldType === 'radio'">
    <el-radio
      v-for="(item, index) in datas"
      :key="index"
      v-model="innerValue"
      :label="item.value"
      >{{ $t("commandParams")[item.key] || item.key }}</el-radio
    >
  </div>
  <!--Select-->
  <el-select
    v-else-if="fieldType === 'select'"
    v-model="innerValue"
    :placeholder="$t('commandParams')[label] || label"
  >
    <el-option
      v-for="(item, index) in datas"
      :label="$t('commandParams')[item.key] || item.key"
      :value="item.value"
      :key="index"
    ></el-option>
  </el-select>
  <!--Time-->
  <el-time-picker
    v-else-if="fieldType === 'time'"
    v-model="innerValue"
    :placeholder="$t('commandParams')[label] || label"
    value-format="HH:mm"
    format="HH:mm"
  ></el-time-picker>
  <!--CheckBox-->
  <el-checkbox
    v-else-if="fieldType === 'checkbox'"
    v-model="innerValue"
    true-label="1"
    false-label="0"
    >{{ $t("commandParams")[label] || label }}</el-checkbox
  >
  <!--CheckBox List-->
  <el-checkbox-group
    v-else-if="fieldType === 'checkboxlist'"
    class="check-group"
    v-model="innerValue"
  >
    <el-checkbox
      v-for="(item, index) in datas"
      :label="item.value"
      :key="index"
      >{{ $t("commandParams")[item.key] || item.key }}</el-checkbox
    >
  </el-checkbox-group>
  <!--Tip-->
  <p v-else-if="fieldType === 'tip'" class="mb-3 tip--warning">
    {{ $t("commandParams")[label] || label }}
  </p>
</template>

<script>
export default {
  props: {
    fieldType: {
      // 类型 textfield,radio
      type: String,
      default: "textfield",
    },
    valueType: {
      // 值类型 String,NumberInt,NumberFloat
      type: String,
      default: "String",
    },
    placeholder: {
      // 提示
      type: String,
      default: "",
    },
    max: {
      // 最大值
      type: [String, Number],
      default: 0,
    },
    min: {
      // 最小值
      type: [String, Number],
      default: 0,
    },
    step: {
      // 步
      type: [String, Number],
      default: 1,
    },
    label: {
      // 标签
      type: String,
      default: "",
    },
    name: {
      // 绑定数据名称
      type: String,
      default: "",
    },
    value: {
      // 绑定值
      type: [String, Number, Array, Date],
      default: null,
    },
    others: {
      // 其他数据
      type: Object,
      default: null,
    },
    datas: {
      // 键值对 [{key:1,value:000}]
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data: () => ({
    innerValue: null,
    // Dialog
    modal: false,
    selected: [],
  }),
  created() {
    this.innerValue = this.value;
  },
  computed: {
    selectList() {
      return this.datas.map((item) => {
        return { text: item.key, value: item.value };
      });
    },
  },
  watch: {
    value(newVal) {
      this.innerValue = newVal;
    },
    innerValue(newVal) {
      this.innerValue = newVal;
      // if (this.valueType === 'String') {
      //   newVal += ''
      // } else if (this.valueType === "NumberInt") {
      //   newVal = parseInt(newVal)
      // } else if (this.valueType === 'NumberFloat') {
      //   newVal = parseFloat(newVal)
      // }

      this.$emit("input", newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.tip--warning {
  color: #ff5722;
  margin-bottom: 10px;
}
.check-group {
  & .el-checkbox {
    margin-right: 30px;
  }
  & .el-checkbox + .el-checkbox {
    margin-left: 0;
  }
}
.number {
  width: 100%;
  .el-input__inner{
    text-align: left;
  }
}
</style>
