<template>
  <el-dialog :title="$t('deviceDetail.detailInfo')" custom-class="v-dialog v-dialog-md v-dialog--mdpadding" :visible.sync="visible" v-if="visibleReal" @closed="closed">

    <el-form label-width="8em" v-loading="loading">
      <p>{{$t('userDetail.basicInfo')}}</p>
     <!-- 车辆 -->
      <template v-if="itemType===0">
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('vehicleDetail.vehiclePlate')">{{vehicle.VehicleName|emptyFormat}}</el-form-item>
            <el-form-item :label="$t('vehicleDetail.peopleName')">{{other.people.PeopleName|emptyFormat}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('vehicleDetail.vehicleColor')">{{$t('color')[vehicle.VehicleNumBackColor]  |emptyFormat}}</el-form-item>
            <el-form-item :label="$t('vehicleDetail.phone')">{{other.people.Mobile|emptyFormat}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('deviceDetail.vehicleType')">{{$t('dict')[findLabel(other.carTypeList, k=> k.ItemID == item.ObjectType)]}}</el-form-item>
            <el-form-item :label="$t('deviceDetail.payEndDate')"><span v-if="item.PayEndDate">{{miment(item.PayEndDate).format()}}</span><span v-else>--</span></el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('deviceDetail.brandIcon')">
              <!-- <template v-if="getBrand()">
                <img :src="require(`../../../assets/brand/${getBrand().VehicleBrandName}.jpg`)" class="brandimg"/>
                {{$t('dict')[getBrand().VehicleBrandName] || getBrand().VehicleBrandName}}
              </template> -->
              <select-brand
                disabled
                large-image
                style="width:100%;"
                use-index
                title=""
                v-model="vehicle.VehicleBrandID"
                :options="other.brandList"
                :props="other.brandProps"
                transform-name
                placement="bottom-start"></select-brand>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item :label="$t('deviceDetail.remark')">{{item.Remark|emptyFormat}}</el-form-item>
          </el-col>
        </el-row>
      </template>
      <!-- 人员 -->
       <template  v-if="itemType===1">
        <el-row>
          <el-col :span="8">
            <el-form-item  :label="$t('vehicleDetail.peopleName')">{{other.people.PersonName|emptyFormat}}</el-form-item>
            <el-form-item  :label="$t('deviceDetail.personType')">{{other.people.personType|emptyFormat}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('vehicleDetail.idcard')">{{other.people.IDCard |emptyFormat}}</el-form-item>
            <el-form-item :label="$t('vehicleDetail.peopleSex')">{{other.people.Sex}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('vehicleDetail.phone')">{{other.people.Phone}}</el-form-item>
            <!-- <el-form-item :label="$t('deviceDetail.personPic')"><span v-if="item.PayEndDate">{{miment(item.PayEndDate).format()}}</span><span v-else>--</span></el-form-item> -->
          </el-col>
        </el-row>
      </template>
      <!-- 物品 -->
       <template  v-if="itemType===2">
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('deviceDetail.articleName')">{{other.people.ArtName}}</el-form-item>
            <el-form-item :label="$t('deviceDetail.articleShape')">{{other.people.ArtTypeID}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('deviceDetail.articleColor')"><span :style="{ backgroundColor: other.people.Color}" class="coloSpan"></span></el-form-item>
            <el-form-item :label="$t('deviceDetail.articleWeight')">{{other.people.Weight}}{{$t('deviceDetail.Unit2')}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('deviceDetail.articlesize')">{{other.people.Length||0}}{{$t('deviceDetail.Unit')}}*{{other.people.Width||0}}{{$t('deviceDetail.Unit')}}*{{other.people.Height|0}}{{$t('deviceDetail.Unit')}}</el-form-item>
          </el-col>
        </el-row>
      </template>
      <p>{{$t('deviceDetail.deviceInfo')}}</p>
      <el-row>
        <el-col :span="8">
          <el-form-item :label="$t('deviceDetail.deviceType')">{{findLabel(other.objectTypeList, k=> k.ItemID == item.MDTModel)}}</el-form-item>
          <!-- <el-form-item :label="$t('deviceDetail.mdtType')">{{other.tracks.Factory}}</el-form-item> -->
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('deviceDetail.deviceID')">{{item.SIM2}}</el-form-item>
          <el-form-item :label="$t('deviceDetail.createTime')">{{miment(item.CreateTime).format()}}</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('deviceDetail.contactInfo')">{{item.AlldayTel}}</el-form-item>
        </el-col>
      </el-row>
      <template v-if="isShowWxActive && $i18n.locale==='zh'" >
        <div :key="-1" v-if="other.wxInfoList.length===0">
          <p>小程序绑定信息</p>
          <div style="text-align:center;" class="imy-1">暂无</div>
        </div>
        <div v-for="(info, index) in other.wxInfoList" :key="index">
          <p>小程序绑定信息<span v-if="other.wxInfoList.length > 1">【{{index+1}}】</span> </p>
          <el-row>
            <el-col :span="8">
              <el-form-item label="车牌号码">{{info.VehicleName}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="车辆品牌">{{info.VehicleBrandName}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="车辆颜色">{{info.Color}}</el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="微信昵称">{{info.NickName | nameFilter}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="微信性别">{{info.Sex===1?'男':'女'}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="微信手机">{{info.Mobile}}</el-form-item>
            </el-col>
          </el-row>
        </div>
      </template>
    <p>{{$t('deviceDetail.simInfo')}}</p>
      <el-row>
        <el-col :span="24">
          <el-form-item :label="$t('deviceDetail.sim')">{{item.SIM}}</el-form-item>
        </el-col>
      </el-row>
    <p>{{$t('deviceDetail.gpsInfo')}}</p>
      <el-row>
        <el-col :span="8">
          <el-form-item :label="$t('deviceDetail.gpsTime')"><span v-if="other.tracks.GPSTime">{{miment(other.tracks.GPSTime).format()}}</span><span v-else>--</span></el-form-item>
          <el-form-item :label="$t('deviceDetail.oil')"><span v-if="other.tracks.OilNum">{{other.tracks.OilNum}} L</span><span v-else>--</span></el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('deviceDetail.rcvTime')"><span v-if="other.tracks.RCVTime">{{miment(other.tracks.RCVTime).format()}}</span><span v-else>--</span></el-form-item>
          <el-form-item :label="$t('deviceDetail.miles')"><span v-if="other.tracks.Mileage">{{other.tracks.Mileage}} Km</span><span v-else>--</span></el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('deviceDetail.speed')"><span v-if="other.tracks.Speed">{{other.tracks.Speed}} /km/h</span><span v-else>--</span></el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item :label="$t('deviceDetail.location')">
            <span-address :lon="other.tracks.Lon" :lat="other.tracks.Lat" show-default></span-address>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('deviceDetail.statsuDes')"><span v-if="other.tracks.StatusDes">{{other.tracks.StatusDes}}</span> <span v-else>--</span></el-form-item>
        </el-col>
      </el-row>
      <p>{{$t('deviceDetail.userInfo')}}</p>
      <el-row>
        <el-col :span="24">
          <el-form-item :label="$t('deviceDetail.holdName')">{{other.holdInfo.FullPathName}}</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('deviceDetail.contacter')">{{other.holdInfo.Contacter}}</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('deviceDetail.contactTel')">{{other.holdInfo.MustTel}}</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('deviceDetail.addr')">{{other.holdInfo.Addr}}</el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item :label="$t('deviceDetail.remark')">{{other.holdInfo.Remark}}</el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
import * as API from '@/api/device'
import mixin from './mixin.js'
import {Query as QuerySkillAPI} from '@/api/skillman'
import { QueryDicts } from '@/api/common'
import miment from 'miment'
import SelectBrand from '@/views/common/select-brand'
export default {
  mixins: [mixin],
  props: {
    itemType: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      id: 0,
      miment,
      item: {},
      vehicle: {},
      isShowWxActive: true,
      other: {
        tracks: {},
        holdInfo: {},
        people: {},
        deviceList: [],
        brandList: [],
        carTypeList: [],
        objectTypeList: [],
        artTypeList:[],
        wxInfoList: [],
        brandProps: {
          label: 'VehicleBrandName',
          value: 'VehicleBrandID'
        },
      }
    }
  },
  components: {
    SelectBrand
  },
  filters: {
    nameFilter(val) {
      if (!val) {
        return ''
      }
      return decodeURIComponent(val)
    }
  },
  methods: {
    fetchItem(id) {
      const promise1 = new Promise(resolve => {
        // GetPersonInfo
        // GetArticleInfo
        if(this.itemType===1){
          API.GetPersonInfo(this.id||0, this.targetHoldID).then(ret => {
            let url = this.href+'/api/posts/image.ashx';
            let url2 = this.href+'/upload/image/';
            this.item = Object.assign({}, ret.data.device, {
              SIM: ret.data.device.SIM ? ret.data.device.SIM : null,
              SIM2: ret.data.device.SIM2 ? ret.data.device.SIM2 : null
            })

            this.other.personTypeList = JSON.parse(ret.data.personTypeList).list
            this.other.people =  Object.assign({}, ret.data.person)

            if (this.other.people.PicPath&&this.other.people.PicPath!=='') {
              this.imageNameList = this.other.people.PicPath.split('|').filter(k => !!k).map(k => ({
                name: k,
                response: k,
                // url_l: `${this.$IMAGE_URL_PREFIX}${k}`,
                // url: `${this.$IMAGE_URL_PREFIX_M}?name=${k}&size=140`
                url_l: `${url2}${k}`,
                url: `${url}?name=${k}&size=140`
              }))
            } else {
              this.imageNameList = []
            }

            resolve(ret)
          })

        }else if(this.itemType===2){
            API.GetArticleInfo(this.id||0, this.targetHoldID).then(ret => {
              let url = this.href+'/api/posts/image.ashx';
              let url2 = this.href+'/upload/image/';
              this.item = Object.assign({}, ret.data.device, {
                SIM: ret.data.device.SIM ? ret.data.device.SIM : null,
                SIM2: ret.data.device.SIM2 ? ret.data.device.SIM2 : null
              })

              if (!this.id && datas && datas.vid) {
                this.item.VehicleID = datas.vid
              }
              if(this.id){
                let vcList = []
                ret.data.videoCircuit?vcList=ret.data.videoCircuit.split(','):'';
                this.videoCircuitList = vcList
              }else{
                this.videoCircuitList=['1']
              }
              this.other.holdInfo = Object.assign({}, ret.data.holdInfo)

              this.vehicle = Object.assign({}, ret.data.vehicle)
              this.other.people = Object.assign({}, ret.data.artInfo)
              this.other.deviceList = ret.data.deviceList.slice(0)
              // this.other.brandList = JSON.parse(ret.data.brandList).dict
              // this.other.carTypeList = JSON.parse(ret.data.carTypeList).list.slice(0)
              this.other.objectTypeList = JSON.parse(ret.data.objectTypeList).list.slice(0) 
              this.other.artTypeList = JSON.parse(ret.data.artTypeList).list

              // if (this.vehicle.VehicleID) {
              //   this.saveVehicle = true
              // } else if (this.id) {
              //   this.saveVehicle = false
              // }

              this.serverPath = this.item.PhotoName
              if (this.serverPath) {
                this.imageUrl = `${url2}${this.serverPath}`
                this.showInstallInfo = true
              } else {
                this.imageUrl = null
                this.showInstallInfo = false
              }
              if (this.item.PhotoName) {
                this.imageNameList = this.item.PhotoName.split('|').filter(k => !!k).map(k => ({
                  name: k,
                  response: k,
                  url_l: `${url2}${k}`,
                  url: `${url}?name=${k}&size=140`
                  // url_l: `${this.$IMAGE_URL_PREFIX}${k}`,
                  // url: `${this.$IMAGE_URL_PREFIX_M}?name=${k}&size=140`
                }))
              } else {
                this.imageNameList = []
              }

              resolve(ret)
            })
        }else{
          API.Get(this.id||0).then(ret => {
            this.item = Object.assign({}, ret.data.device, {
              SIM: ret.data.device.SIM ? ret.data.device.SIM : null,
              SIM2: ret.data.device.SIM2 ? ret.data.device.SIM2 : null
            })

            this.other.holdInfo = Object.assign({}, ret.data.holdInfo)
            this.vehicle = Object.assign({}, ret.data.vehicle)
            this.other.people = Object.assign({}, ret.data.people)
            this.other.deviceList = ret.data.deviceList.slice(0)
            this.other.brandList = JSON.parse(ret.data.brandList).dict
            this.other.carTypeList = JSON.parse(ret.data.carTypeList).list.slice(0)
            this.other.objectTypeList = JSON.parse(ret.data.objectTypeList).list.slice(0)
            this.other.tracks = ret.data.tracks
            if (this.vehicle.VehicleID) {
              this.saveVehicle = true
            } else if (id) {
              this.saveVehicle = false
            }

            this.serverPath = this.item.PhotoName
            if (this.serverPath) {
              this.imageUrl = `${this.$IMAGE_URL_PREFIX}${this.serverPath}`
              this.showInstallInfo = true
            } else {
              this.imageUrl = null
              this.showInstallInfo = false
            }
            resolve(ret)
          })
        }
      
      })

      const promise2 = new Promise(resolve => {
        QueryDicts(1, 100, '', '', [], [58, 60]).then(ret => {
          this.objectTypeList = ret.data.list.filter(k => k.DictID == 58).slice()
          this.trackTypeList = ret.data.list.filter(k => k.DictID == 60).slice()
          resolve()
        })
      })

      const promise3 = new Promise(resolve => {
        QuerySkillAPI(1, 1000, '', '', [], null, true).then(ret => {
          this.skillList = ret.data.list.slice()
          resolve()
        })
      })

      const promise4 = new Promise((resolve, reject) => {
        API.QueryDeviceWxInfo(this.id).then(ret => {
          this.other.wxInfoList = ret.data.data
          resolve()
        }).catch(reject)
      })

      return Promise.all([promise1, promise2, promise3, promise4])
    },
    findLabel(dict, func) {
      const index = dict.findIndex(func)
      if (index != -1) {
        return dict[index].ItemName
      } else {
        return '--'
      }
    },
    resetForms() {

    },
    getBrand() {
      if (!this.other.brandList.length) {
        return { VehicleBrandID:999, VehicleBrandName:'其他' }
      }
      const index = this.other.brandList.findIndex(k => k.VehicleBrandID == this.vehicle.VehicleBrandID)
      if (index == -1) {
        return { VehicleBrandID:999, VehicleBrandName:'其他' }
      }
      return this.other.brandList[index]
    }
  },
}
</script>

<style lang="scss" scoped>
p {
  line-height: 32px;
  background-color: rgba(0, 0, 0, .05);
  height: 32px;
  margin-bottom: 8px;
  text-align: center;
}
.el-form-item--small.el-form-item {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, .84);
}
.brandimg {
  height: 21px;
  margin-right: 4px;
  vertical-align: middle;
}
.coloSpan{
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;
  top: 3px;
}
</style>
